<script>
/**
 * Consolidated component
 */
import moment from "moment";
export default {
  props: ["events", "states", "region", "total", "state", "loaderData"],
  data() {
    return {
      time: null,

      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          events: {
            click: (event, chartContext, config) => {
              let provinceIndex = config.dataPointIndex;
              let stateIndex = config.seriesIndex;
              if (provinceIndex >= 0 && stateIndex >= 0) {
                this.pushProvinceConsolidated({
                  province: config.globals.labels[provinceIndex],
                  state: stateIndex,
                });
              }
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: [
          "#2A60E9",
          "#48FFFB",
          "#66DA26",
          "#FEC53A",
          "#FE753A",
          "#E30D0D",
          "#4F4F50",
        ],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          fontWeight: 900,
          horizontalAlign: "center",
          offsetX: 40,
          itemMargin: {
            horizontal: 20,
            vertical: 0,
          },
        },
      },
    };
  },
  watch: {
    events(newVal) {
      this.setSeries(newVal);
    },
  },
  mounted() {
    //this.setSeries(this.events);
  },
  methods: {
    setSeries(events) {
      this.time = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      let data = events;

      let keys = Object.keys(data);
      let offline = [];
      let lessZero = [];
      let zero = [];
      let two = [];
      let eight = [];
      let twelve = [];
      let fourteen = [];
      keys.map((obj) => {
        this.chartOptions.xaxis.categories.push(obj);
        offline.push(data[obj].offline);
        lessZero.push(data[obj].lessZero);
        zero.push(data[obj].zero);
        two.push(data[obj].two);
        eight.push(data[obj].eight);
        twelve.push(data[obj].twelve);
        fourteen.push(data[obj].fourteen);
      });

      this.series = [
        {
          name: `Temp < 0 (${this.states.lessZero})`,
          data: lessZero,
        },
        {
          name: `0 < Temp < 2 (${this.states.zero})`,
          data: zero,
        },
        {
          name: `2 < Temp < 8 (${this.states.two})`,
          data: two,
        },
        {
          name: `8 < Temp < 12 (${this.states.eight})`,
          data: eight,
        },
        {
          name: `12 < Temp < 14 (${this.states.twelve})`,
          data: twelve,
        },
        {
          name: `14 > Temp (${this.states.fourteen})`,
          data: fourteen,
        },
        {
          name: `Offline (${this.states.offline})`,
          data: offline,
        },
      ];
      setTimeout(() => {
        let chart = this.$refs.chartConsol;
        if (this.state != "0")
          chart.hideSeries(`Temp < 0 (${this.states.lessZero})`);
        else chart.showSeries(`Temp < 0 (${this.states.lessZero})`);
        if (this.state != "1")
          chart.hideSeries(`0 < Temp < 2 (${this.states.zero})`);
        else chart.showSeries(`0 < Temp < 2 (${this.states.zero})`);
        if (this.state != "2")
          chart.hideSeries(`2 < Temp < 8 (${this.states.two})`);
        else chart.showSeries(`2 < Temp < 8 (${this.states.two})`);
        if (this.state != "3")
          chart.hideSeries(`8 < Temp < 12 (${this.states.eight})`);
        else chart.showSeries(`8 < Temp < 12 (${this.states.eight})`);
        if (this.state != "4")
          chart.hideSeries(`12 < Temp < 14 (${this.states.twelve})`);
        else chart.showSeries(`12 < Temp < 14 (${this.states.twelve})`);
        if (this.state != "5")
          chart.hideSeries(`14 > Temp (${this.states.fourteen})`);
        else chart.showSeries(`14 > Temp (${this.states.fourteen})`);
        if (this.state != "6")
          chart.hideSeries(`Offline (${this.states.offline})`);
        else chart.showSeries(`Offline (${this.states.offline})`);
      }, 5);

      /* for (let i in cumul) {
        dataSeriesRain[i] = {
          x: cumul[i].created,
          y: cumul[i].created == "14/3/2022" ? parseFloat(cumul[i].cumulRain / 10).toFixed(2) : cumul[i].created == "22/3/2022" ? parseFloat(cumul[i].cumulRain - 486).toFixed(2) : parseFloat(cumul[i].cumulRain).toFixed(2),
        };
      }
      this.series = [
        {
          type: "bar",
          name: "Cumul Pluviométrique (mm)",
          data: dataSeriesRain,
        },
      ]; */
    },
    pushProvinceConsolidated({ province, state }) {
      this.$router.push({
        name: "consolidated-province",
        params: { province: province, state: state },
      });
    },
  },
};
</script>

<template>
  <div class="card" style="min-height: 750px">
    <div
      v-if="loaderData == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="loaderData == false" class="card-body">
      <div class="row">
        <div class="col-12 text-center">
          <h4>{{ region }} ({{ total }})</h4>
          <p class="mb-0">{{ time }}</p>
        </div>
      </div>
      <div>
        <apexchart
          ref="chartConsol"
          height="400"
          type="bar"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<style scoped>
.apex-charts text {
  fill: #000;
}
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
</style>