<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ConsolidatedRegion from "./consolidated-region";
import {
  layoutMethods,
  layoutComputed,
  consolidatedMethods,
  consolidatedComputed,
} from "@/state/helpers";
/**
 * Consolidated-Region component
 */
export default {
  page: {
    title: "Consolidated-Region",
    meta: [{ name: "Consolidated-Region" }],
  },
  components: {
    Layout,
    PageHeader,
    ConsolidatedRegion,
  },
  mounted() {
    this.changeMenuType();
  },
  created() {
    this.retrieveConsolidated();
  },
  data() {
    return {
      title: "Consolidated-Region",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Consolidated-Region",
          active: true,
        },
      ],

      loaderData: true,

      cptStates: {
        offline: 0,
        lessZero: 0,
        zero: 0,
        two: 0,
        eight: 0,
        twelve: 0,
        fourteen: 0,
      },

      totalDevices: 0,

      region: null,
      state: null,

      consolidatedList: {},
      consolidatedData: {},
    };
  },
  computed: {
    ...layoutComputed,
    ...consolidatedComputed,
  },
  methods: {
    ...layoutMethods,
    ...consolidatedMethods,

    retrieveConsolidated() {
      this.getConsolidatedBy().then(() => {
        this.loaderData = false;
      });
    },
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },

    getNameRegion(region) {
      (region == "0") ? this.region = "Tanger-Tetouan-Al Hoceima" : 
      (region == "1") ? this.region = "Oriental" :
      (region == "2") ? this.region = "Fès-Meknès" :
      (region == "3") ? this.region = "Rabat-Salé-Kénitra" :
      (region == "4") ? this.region = "Béni Mellal-Khénifra" :
      (region == "5") ? this.region = "Grand Casablanca-Settat" :
      (region == "6") ? this.region = "Marrakech-Safi" :
      (region == "7") ? this.region = "Drâa-Tafilalet" :
      (region == "8") ? this.region = "Souss-Massa" :
      (region == "9") ? this.region = "Guelmim-Oued Noun" :
      (region == "10") ? this.region = "Laayoune-Sakia El Hamra" :
      (region == "11") ? this.region = "Eddakhla-Oued Eddahab" : null;
    },
  },
  watch: {
    getConsolidated(newVal) {
      let region = this.$route.params.region;
      this.state = this.$route.params.state;
      this.getNameRegion(region);
      
      let keys = Object.keys(newVal);
      keys.map((obj) => {
        if (newVal[obj].region && newVal[obj].region.name == this.region) {
          this.totalDevices = this.totalDevices += 1;
          if (!this.consolidatedList[newVal[obj].province.name]) {
            this.consolidatedList[newVal[obj].province.name] = {
              offline: 0,
              lessZero: 0,
              zero: 0,
              two: 0,
              eight: 0,
              twelve: 0,
              fourteen: 0,
            };
          }
          if (newVal[obj].event) {
            let now = new Date();
            let dateEvent = new Date(newVal[obj].event.createdAt);
            var diffMs = now.getTime() - dateEvent.getTime();
            if (diffMs > 1000 * 60 * 15) {
              this.consolidatedList[newVal[obj].province.name].offline += 1;
              this.cptStates.offline += 1;
            } else {
              let temp = newVal[obj].event.temp;
              if (temp < 0) {
                this.consolidatedList[newVal[obj].province.name].lessZero += 1;
                this.cptStates.lessZero += 1;
              } else if (temp <= 2) {
                this.consolidatedList[newVal[obj].province.name].zero += 1;
                this.cptStates.zero += 1;
              } else if (temp <= 8) {
                this.consolidatedList[newVal[obj].province.name].two += 1;
                this.cptStates.two += 1;
              } else if (temp <= 12) {
                this.consolidatedList[newVal[obj].province.name].eight += 1;
                this.cptStates.eight += 1;
              } else if (temp <= 14) {
                this.consolidatedList[newVal[obj].province.name].twelve += 1;
                this.cptStates.twelve += 1;
              } else if (temp > 14) {
                this.consolidatedList[newVal[obj].province.name].fourteen += 1;
                this.cptStates.fourteen += 1;
              }
            }
          } else {
            this.consolidatedList[newVal[obj].province.name].offline += 1;
            this.cptStates.offline += 1;
          }
        }
      });
      this.consolidatedData = this.consolidatedList;
    },
  },
  beforeDestroy() {
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <ConsolidatedRegion
      v-bind:events="consolidatedData"
      :states="cptStates"
      :region="region"
      :total="totalDevices"
      :state="state"
      :loaderData="loaderData"
    />
  </Layout>
</template>